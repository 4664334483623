import React from 'react';

/**
 * Highlight component
 *
 * This component highlights parts of the text that match the given search words.
 * It allows custom components for highlighted and non-highlighted text,
 * and a root component to wrap the entire text. By default, it uses <mark>
 * for highlighted text and <span> for non-highlighted text.
 *
 * @param {string} text - The full text where search words need to be highlighted.
 * @param {string[]} searchWords - Array of words to search for and highlight.
 * @param {React.ElementType} [highlightedComponent=mark] - Component used to wrap highlighted text.
 * @param {React.ElementType} [nonHighlightedComponent=span] - Component used to wrap non-highlighted text.
 * @param {React.ElementType} [rootComponent=div] - Component used to wrap the entire text (including highlighted and non-highlighted parts).
 */
type HighlightProps = {
	text: string;
	searchWords: string[];
	highlightedComponent?: React.ElementType;
	nonHighlightedComponent?: React.ElementType;
	rootComponent?: React.ElementType;
};

export const Highlight: React.FC<HighlightProps> = ({
	text,
	searchWords,
	highlightedComponent: HighlightedComponent = 'mark', // Default to <mark>
	nonHighlightedComponent: NonHighlightedComponent = 'span', // Default to <span>
	rootComponent: RootComponent = 'div', // Default to <div>
}) => {
	if (!searchWords || searchWords.length === 0) {
		return <RootComponent>{text}</RootComponent>;
	}

	// Create a regular expression to match any of the search words, case-insensitive
	const regex = new RegExp(`(${searchWords.join('|')})`, 'gi');

	// Split the text into parts based on the search words, matching the regex
	const parts = text.split(regex).map((part, index) => {
		// Determine if the current part matches any of the search words
		const isHighlighted = searchWords.some((word) => word.toLowerCase() === part.toLowerCase());

		// Choose the appropriate component based on whether the part is highlighted
		const TagName = isHighlighted ? HighlightedComponent : NonHighlightedComponent;

		// Render the part inside the appropriate component (highlighted or not)
		return <TagName key={index}>{part}</TagName>;
	});

	// Wrap the entire text in the root component and return
	return <RootComponent>{parts}</RootComponent>;
};
