import { IVariationDetails } from '~/lib/data-contract';
import { StyledProductList, StyledSearchProductsArea, StyledSearchProductsAreaFooter } from './styled';
import { ProductListItem } from '~/features/products/components/ProductCardList/ProductListItem';
import { Button } from '~/shared/components/Button/Button';
import { PopularCategoriesLinks } from './PopularCategoriesLinks';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';

type SearchProductsAreaProps = {
	products: IVariationDetails[];
	totalResults: number;
	onSubmit: () => void;
};

export const SearchProductsArea = ({ products, totalResults, onSubmit }: SearchProductsAreaProps) => {
	const { translate } = useTranslation();
	const hasResults = products.length > 0;

	return (
		<>
			{!hasResults && <PopularCategoriesLinks />}
			<StyledSearchProductsArea
				aria-live="polite"
				hasResults={hasResults}
			>
				<StyledProductList aria-labelledby="search-results">
					{products.map((product) => (
						<ProductListItem product={product} />
					))}
				</StyledProductList>

				<StyledSearchProductsAreaFooter hasResults={!!totalResults}>
					<Button onClick={onSubmit}>{translate('search.see-all-products', { count: totalResults })}</Button>
				</StyledSearchProductsAreaFooter>
			</StyledSearchProductsArea>
		</>
	);
};
