import styled from '@emotion/styled';
import Link from '~/shared/components/Link/Link';
import { StyledRichText } from '~/shared/components/RawHtml/styled';
import { breakpoints } from '~/theme';
import { getColumn } from '~/theme/lib/grid';
import { themeBase } from '~/theme/lib/themes/themeBase';

export const StyledFooter = styled.footer(() => ({
	color: themeBase.colors.white,
	backgroundColor: themeBase.colors.black,
	paddingTop: themeBase.space[6],
	paddingBottom: themeBase.space[10],
	paddingLeft: themeBase.space[2],
	paddingRight: themeBase.space[2],

	[breakpoints.sm]: {
		paddingLeft: themeBase.space[4],
		paddingRight: themeBase.space[4],
	},

	[breakpoints.md]: {
		paddingLeft: themeBase.space[6],
		paddingRight: themeBase.space[6],
	},
}));

export const StyledFooterHeader = styled.div(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	marginBottom: themeBase.space[6],
	width: '100%',
}));

export const StyledFooterLogo = styled.div<{
	maxHeightScale: number;
	maxHeightScaleDesktop: number;
}>(({ maxHeightScale, maxHeightScaleDesktop }) => ({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	maxWidth: getColumn(2),
	marginBottom: themeBase.space[8],
	maxHeight: `calc(40px * ${maxHeightScale})`,

	[breakpoints.sm]: {
		maxWidth: getColumn(1),
	},

	[breakpoints.md]: {
		maxHeight: `calc(110px * ${maxHeightScaleDesktop})`,
		marginBottom: themeBase.space[8],
		maxWidth: getColumn(2, true),
	},
}));

export const StyledFooterBCorp = styled.div();

export const StyledFooterBCorpLink = styled(Link)(() => ({
	textDecoration: 'none !important',
}));

export const StyledFooterBCorpLogo = styled.div(() => ({
	display: 'flex',
	justifyContent: 'flex-end',
	marginTop: themeBase.space[2],
	gap: themeBase.space[5],
}));

export const StyledFooterInner = styled.div(() => ({
	color: themeBase.colors.white,
	display: 'grid',
	borderBottom: `1px solid ${themeBase.colors.grey60}`,
	marginBottom: themeBase.space[7],

	[breakpoints.md]: {
		marginBottom: 0,
		borderBottomWidth: 0,
		gap: 'var(--grid-gutter)',
		gridTemplateColumns: `${getColumn(3, true)} ${getColumn(6, true)} ${getColumn(2, true)}`,
	},
}));

export const StyledFooterLeft = styled.div(() => ({
	display: 'flex',
	flexDirection: 'column',

	[`${StyledRichText}`]: {
		...themeBase.textStyles.bodySm,

		[breakpoints.md]: {
			fontSize: themeBase.fontSizes['xs'],
		},
	},
}));

export const StyledSocialIcons = styled.div(() => ({
	marginTop: 'auto',
	paddingTop: themeBase.space[4],
	marginBottom: themeBase.space[6],

	[breakpoints.md]: {
		marginBottom: '0',
	},
}));
