import { CSSProperties } from 'react';
import { StyledText } from './styled';

export type TextVariants =
	| 'display1'
	| 'display2'
	| 'display3'
	| 'display3Push'
	| 'display4'
	| 'display5'
	| 'body'
	| 'bodyBold'
	| 'bodySm'
	| 'bodySmBold'
	| 'bodyXs'
	| 'bodyXsBold'
	| 'caption'
	| 'label'
	| 'suggestion';

export type TextProps = {
	variant?: TextVariants;
	marginBottom?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10';
	marginTop?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10';
	alignment?: CSSProperties['textAlign'];
};

/**
 * Override rendered markup with `as` attribute. For instance:
 *
 * ```tsx
 * <Text as="span">An span in markup.</Text>
 * ```
 */

export const Text = StyledText;
