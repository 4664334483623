import styled from '@emotion/styled';
import { StyledList } from '../SearchDialogContent/styled';
import { breakpoints } from '~/theme';

export const StyledSuggestionsList = styled(StyledList)(({ theme }) => ({
	display: 'grid',
	gridAutoFlow: 'column',
	gridTemplateRows: 'repeat(8, auto)',
	gap: '8px',
	width: 'fit-content',
	columnGap: theme.space[8],

	[breakpoints.md]: {
		gridTemplateRows: 'repeat(16, auto)',
		gridTemplateColumns: 'auto',
	},
}));
