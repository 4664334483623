import { Close } from '@radix-ui/react-dialog';
import { memo } from 'react';
import CloseIcon from '~/icons/close.svg';
import { StyledCloseButton, StyledCloseButtonContent } from './styled';
import { Text } from '~/shared/components/Text/Text';

type Props = {
	label?: string;
	onCloseClick?: () => void;
};

export const CloseButton = memo(({ label, onCloseClick }: Props) => (
	<Close asChild>
		<StyledCloseButton
			onClick={onCloseClick}
			aria-label="Close"
		>
			<StyledCloseButtonContent>
				<CloseIcon />
				{label && <Text variant="label">{label}</Text>}
			</StyledCloseButtonContent>
		</StyledCloseButton>
	</Close>
));
