import { useState, useEffect, useCallback } from 'react';

function useVisualViewport(): number {
	const [viewportHeightOffset, setViewportHeightOffset] = useState<number>(0);

	const handleViewportChange = useCallback(() => {
		if (!visualViewport?.height) {
			return;
		}

		const heightDifference = window.innerHeight - visualViewport.height;
		if (heightDifference !== viewportHeightOffset) {
			setViewportHeightOffset(heightDifference);
		}
	}, [viewportHeightOffset]);

	useEffect(() => {
		if (visualViewport) {
			visualViewport.addEventListener('resize', handleViewportChange);
		}

		return () => {
			if (visualViewport) {
				visualViewport.removeEventListener('resize', handleViewportChange);
			}
		};
	}, [handleViewportChange]);

	return viewportHeightOffset;
}

export default useVisualViewport;
