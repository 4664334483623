import { ISearchResult } from '~/lib/data-contract';

export interface ISearchResultItem {
	text: string;
	link: string;
}

export const getSuggestedCategories = (suggestedCategoriesResults?: ISearchResult): ISearchResultItem[] => {
	if (!suggestedCategoriesResults || !suggestedCategoriesResults?.categories) {
		return [];
	}

	return suggestedCategoriesResults.categories.flatMap((category) =>
		category.items.map((item) => ({
			text: item.searchTitle,
			link: item.searchUrl,
		})),
	);
};
