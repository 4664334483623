import styled from '@emotion/styled';
import { ComponentProps, forwardRef } from 'react';
import SearchIcon from '~/icons/search.svg';
import { InputField } from '~/shared/components/FormElements/components/InputField/InputField';
import { StyledInput, StyledInputBox } from '~/shared/components/FormElements/components/InputField/styled';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { breakpoints } from '~/theme';
import { getColumn } from '~/theme/lib/grid';

const StyledInputField = styled(InputField)(({ theme }) => ({
	svg: {
		width: theme.fontSizes.xl,
		strokeWidth: 1.5,
		height: 'auto',
		flexShrink: 0,
		cursor: 'pointer',
		transform: `color ${theme.animations.timingShort}`,

		'&:hover': {
			color: theme.forms.textColor,
		},
	},

	[`${StyledInputBox}`]: {
		height: theme.space[9],
		boxShadow: '0 0.5px currentColor',
	},

	[`${StyledInput}`]: {
		paddingLeft: 0,
	},

	[breakpoints.lg]: {
		width: getColumn(5, true),
	},
}));

export const SearchInputField = forwardRef<HTMLInputElement, ComponentProps<typeof StyledInputField>>(({ onSubmit, ...props }, ref) => {
	const { translate } = useTranslation();
	return (
		<StyledInputField
			ref={ref}
			placeholder={translate('search.menu-label')}
			hideLabel
			prepend={<SearchIcon onClick={onSubmit} />}
			{...props}
		/>
	);
});
