import React from 'react';
import { Highlight } from './components/Highlight';
import Link from '~/shared/components/Link/Link';
import { Text } from '~/shared/components/Text/Text';
import { StyledListLabel, StyledQuickLinksArea } from '../SearchDialogContent/styled';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { StyledSuggestionsList } from './styled';

export interface ISearchResultItem {
	text: string;
	link: string;
}

type Props = {
	results: ISearchResultItem[];
	searchWords: string[];
};

const HighlightedComponent = ({ children }: any) => (
	<Text
		as="mark"
		variant="suggestion"
		style={{ backgroundColor: 'inherit', fontWeight: '800' }}
	>
		{children}
	</Text>
);

const NonHighlightedComponent = ({ children }: any) => (
	<Text
		as="span"
		variant="suggestion"
	>
		{children}
	</Text>
);

export const SearchSuggestionsResults: React.FC<Props> = ({ results, searchWords }) => {
	const { translate } = useTranslation();

	if (!results.length) {
		return null;
	}

	return (
		<StyledQuickLinksArea>
			<StyledListLabel
				as="h3"
				variant="bodySm"
				id="suggested-categories"
			>
				{translate('search.suggested-categories')}
			</StyledListLabel>
			<StyledSuggestionsList aria-labelledby="suggested-categories">
				{results.map((item, index) => (
					<li key={index}>
						<Link href={item.link}>
							<Highlight
								text={item.text}
								searchWords={searchWords}
								highlightedComponent={HighlightedComponent}
								nonHighlightedComponent={NonHighlightedComponent}
							/>
						</Link>
					</li>
				))}
			</StyledSuggestionsList>
		</StyledQuickLinksArea>
	);
};
