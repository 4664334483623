import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme';

export type StyledPriceProps = {
	old?: boolean;
	discount?: boolean;
	stockAvailable?: boolean;
};

export const StyledPrice = styled.span<StyledPriceProps>(
	({ theme, stockAvailable }) => ({
		display: 'block',
		fontSize: theme.fontSizes['2xs'],
		fontWeight: !stockAvailable ? theme.fontWeights.regular : theme.fontWeights.bold,
		lineHeight: theme.lineHeights.base,
		color: theme.colors.productCardPrice,

		[breakpoints.sm]: {
			fontSize: theme.fontSizes.xs,
		},
	}),
	ifProp('old', ({ theme }) => ({
		textDecoration: 'line-through',
		color: theme.colors.blackMeta,
		opacity: 0.5,
		fontWeight: theme.fontWeights.regular,
	})),
	ifProp('discount', ({ theme }) => ({
		color: theme.colors.productCardDiscount,
	})),
);
