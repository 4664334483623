import { memo } from 'react';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import Link from '~/shared/components/Link/Link';
import { StyledPopularCategoriesList, StyledListLabel, StyledPopularCategoriesLinksArea } from './styled';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';

export const PopularCategoriesLinks = memo(() => {
	const { data: frame } = useFrame();
	const { translate } = useTranslation();
	const itemCount = frame.search?.popularCategoriesLinks?.length;

	if (!frame.search?.popularCategoriesLinks) {
		return null;
	}

	return (
		<StyledPopularCategoriesLinksArea aria-live="polite">
			<StyledListLabel
				as="h3"
				variant="bodySm"
				id="popular-category-links"
			>
				{translate('search.popular-category-links')}
			</StyledListLabel>
			<StyledPopularCategoriesList
				rows={4}
				itemCount={itemCount}
			>
				{frame.search?.popularCategoriesLinks.map((link) => (
					<li key={`popular-category-link-${link.id}`}>
						<Link
							title={link.title}
							href={link.url}
						>
							{link.text}
						</Link>
					</li>
				))}
			</StyledPopularCategoriesList>
		</StyledPopularCategoriesLinksArea>
	);
});
