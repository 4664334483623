import { IVariationDetails } from '~/lib/data-contract';
import { ImageMedia } from '~/shared/components/ImageMedia/ImageMedia';
import fallbackImage from '~/assets/fallback-product.svg';
import { imageSizes, StyledPriceWrapper, StyledProductListItem, StyledText } from './Styled';
import { Text } from '~/shared/components/Text/Text';
import { useFormattedPrice } from '~/shared/hooks/useFormattedPrice';
import Link from '~/shared/components/Link/Link';
import { Price } from '../ProductCard/components/Price/Price';

type ProductCardListProps = {
	product: IVariationDetails;
};

export const ProductListItem = ({ product }: ProductCardListProps) => {
	const formattedUnitPrice = useFormattedPrice(product.price, true);
	const formattedDiscountPrice = useFormattedPrice(product?.discountedPrice, true);
	const isDiscount = Boolean(formattedDiscountPrice);

	if (!product || !product.url) {
		return null;
	}

	return (
		<li aria-labelledby={`product-${product.id}`}>
			<Link
				href={product.url}
				aria-label={`View details for ${product?.name}`}
			>
				<StyledProductListItem>
					<ImageMedia
						image={product.image ?? fallbackImage}
						sizes={imageSizes}
						objectFit="contain"
						width={50}
						height={50}
					/>
					<div>
						<Text
							variant="label"
							aria-label={`Brand: ${product?.brand?.name}`}
						>
							{product?.brand?.name}
						</Text>
						<Text
							variant="label"
							aria-label={`Category: ${product?.categoryLink?.text}`}
						>
							{product?.categoryLink?.text}
						</Text>
						<StyledText
							variant="caption"
							color=" "
							aria-label={`Product name: ${product?.name}`}
						>
							{product?.name}
						</StyledText>
					</div>
					<StyledPriceWrapper>
						{isDiscount && (
							<Price
								discount
								aria-label={`Discounted price: ${formattedDiscountPrice}`}
							>
								{formattedDiscountPrice}
							</Price>
						)}
						<Price
							old={isDiscount}
							aria-label={`Original price: ${formattedUnitPrice}`}
						>
							{formattedUnitPrice}
						</Price>
					</StyledPriceWrapper>
				</StyledProductListItem>
			</Link>
		</li>
	);
};
