import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Content } from '@radix-ui/react-dialog';
import { StyledBrand } from '~/features/products/components/ProductCard/components/Brand/Brand';
import { StyledResetButton } from '~/shared/components/Button/styled';
import { dialogContentEnter, dialogContentExit } from '~/shared/components/Dialog/styled';
import { StyledInput, StyledInputRoot } from '~/shared/components/FormElements/components/InputField/styled';
import { Text } from '~/shared/components/Text/Text';
import { breakpoints } from '~/theme';
import { getColumn, getGutter } from '~/theme/lib/grid';

export const StyledDialogContent = styled(Content)(({ theme }) => ({
	position: 'absolute',
	zIndex: theme.zIndices.dialogOverlay + 1,
	top: 0,
	left: 0,
	width: '100vw',
	height: '100vh',
	overflowY: 'auto',
	'--translateOpenFrom': `0, -10%`,
	'--translateCloseTo': `0, -5%`,

	bottom: 'unset',
	paddingTop: theme.space.dialog.topOffset.sm,

	[breakpoints.md]: {
		top: 0,
		bottom: 'unset',
		paddingTop: theme.space.dialog.topOffset.sm,
	},

	'&[data-state="open"]': {
		animation: `${dialogContentEnter} 0.5s ${theme.animations.easeOutCirc}`,
	},

	'&[data-state="closed"]': {
		animation: `${dialogContentExit} 0.3s ${theme.animations.easeSmooth}`,
	},
}));

export const StyledDialogContainer = styled.div(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	background: theme.colors.white,

	'> :first-of-type': {
		position: 'sticky',
		top: 0,
		zIndex: 1,
	},
}));

export const StyledDialogPane = styled.div<{ viewportHeight?: number }>(({ theme, viewportHeight = 0 }) => ({
	position: 'relative',
	background: theme.colors.grey10,
	color: theme.colors.black,
	gap: theme.space[4],
	minHeight: `calc(100vh + ${viewportHeight}px)`,

	[breakpoints.md]: {
		minHeight: 'initial',
		background: theme.colors.white,
		display: 'grid',
		gridTemplateRows: 'auto repeat(2, 1fr)',
		gridTemplateColumns: 'repeat(4, 1fr)',
		gridTemplateAreas: `
			'search-results search-results search-results quick-links '
			'search-results search-results search-results quick-links'
			'search-results search-results search-results quick-links'
		`,
	},
}));

// Animate in the caret after transform animation is done on container
const caretDelayedDisplay = (caretColor: string) =>
	keyframes({
		to: {
			caretColor: caretColor,
		},
	});

export const StyledSearchInputArea = styled.div(({ theme }) => ({
	position: 'relative',
	alignItems: 'flex-end',
	height: theme.space[9],

	[`${StyledInput}`]: {
		// Delay caret display for better UX. Transform animationDuration is 0.5s.
		caretColor: 'transparent',
		animation: `${caretDelayedDisplay(theme.colors.primary)} 0.5s 0.4s forwards`,
	},

	[breakpoints.md]: {
		borderBottom: `solid 1px ${theme.colors.navigationBackground}`,
		alignItems: 'center',
		justifyContent: 'center',
	},

	[`${StyledInputRoot}`]: {
		width: '100%',
	},
}));

export const StyledQuickLinksArea = styled.div(({ theme }) => ({
	gridArea: 'quick-links',
	background: theme.colors.grey10,
	padding: `${theme.space[6]} ${theme.space[3]}`,
}));

export const StyledSearchFirstPagesArea = styled.div(({ theme }) => ({
	gridArea: 'quick-links',
	backgroundColor: theme.colors.navigationBackground,
	padding: `${theme.space[6]} ${theme.space[4]}`,

	[breakpoints.md]: {
		padding: `${theme.space[6]} ${getGutter()} ${theme.space[6]} ${getColumn(1, true)}`,
	},
}));

export const StyledSearchProductsArea = styled.div<{ hasResults: boolean }>(({ theme, hasResults }) => ({
	gridArea: 'search-results',
	padding: `0 0 0 ${theme.space[3]}`,
	display: hasResults ? 'block' : 'none',
	backgroundColor: theme.colors.white,
	[breakpoints.md]: {
		marginBottom: 0,
		padding: `0 0 0 ${theme.space[3]}`,
	},
}));

export const StyledSearchProductsAreaFooter = styled.div<{ hasResults: boolean }>(({ theme, hasResults }) => ({
	display: hasResults ? 'flex' : 'none',
	justifyContent: 'center',
	padding: `${theme.space[2]}`,
}));

export const StyledCloseArea = styled.div({
	width: '100%',
	height: '100%',
	position: 'absolute',
	top: 0,
	left: 0,
});

export const StyledCloseButtonContent = styled.div(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	gap: theme.space[2],
}));

export const StyledCloseButton = styled(StyledResetButton)(({ theme }) => ({
	position: 'absolute',
	top: '50%',
	transform: 'translate(-50%, -50%)',
	right: theme.space[1],
	display: 'block',
	fontSize: theme.sizes.iconXs,
}));

export const StyledListLabel = styled(Text)(({ theme }) => ({
	color: theme.colors.grey60,
	marginBottom: theme.space[4],
}));

export const StyledProductsList = styled.ol(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
	gap: theme.space[3],
	...theme.textStyles.bodySm,

	'& > :nth-of-type(4)': {
		display: 'none',
	},

	[`${StyledBrand}`]: {
		whiteSpace: 'nowrap',
	},
	whiteSpace: 'normal',

	[breakpoints.lg]: {
		gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
		'& > :nth-of-type(4)': {
			display: 'flex',
		},
	},
}));

export const StyledList = styled.ol(({ theme }) => ({
	display: 'grid',
	gap: theme.space[1],
	...theme.textStyles.bodySm,
	a: {
		fontWeight: theme.fontWeights.regular,
		fontSize: theme.fontSizes.xs,
	},
}));

export const StyledProductList = styled.ol(({ theme }) => ({
	display: 'grid',
	gap: theme.space[1],
	marginTop: theme.space[1],
	...theme.textStyles.bodySm,
	a: {
		fontWeight: theme.fontWeights.regular,
		fontSize: theme.fontSizes.xs,
	},

	li: {
		display: 'none',
	},

	'li:nth-child(-n+4)': {
		display: 'grid',
	},

	[breakpoints.md]: {
		'li:nth-child(-n+8)': {
			display: 'grid',
		},
	},
}));

export const StyledSearchProductsCarousel = styled.div({
	display: 'inline-block',
	width: getColumn(3.1),

	':hover': {
		textDecoration: 'none',
		color: 'inherit',
	},

	[breakpoints.sm]: {
		width: getColumn(1.9),
	},

	[breakpoints.md]: {
		width: getColumn(3.21),
	},

	[breakpoints.xl]: {
		width: getColumn(2.2),
	},
});

export const StyledProductListItem = styled.li({
	display: 'flex',
	flexDirection: 'column',
});

export const StyledPopularCategoriesLinksArea = styled.div(({ theme }) => ({
	padding: `${theme.space[6]} ${theme.space[3]}`,
	gridColumn: '1 / span 3',
	backgroundColor: theme.colors.white,
}));

export const StyledPopularCategoriesList = styled.ul<{ rows: number; itemCount?: number }>(({ theme, rows, itemCount = 6 }) => ({
	display: 'grid',
	gridAutoFlow: 'column',
	gap: theme.space[1],
	...theme.textStyles.bodySm,
	...(itemCount && { gridTemplateRows: `repeat(${Math.ceil(itemCount / 2)}, auto)` }),

	a: {
		fontWeight: theme.fontWeights.regular,
		fontSize: theme.fontSizes.xs,
	},

	[breakpoints.md]: {
		gridTemplateRows: `repeat(${rows}, auto)`,
		gridAutoFlow: 'column',
		justifyContent: 'start',
		columnGap: theme.space[8],
	},
}));
