import Image from 'next/image';
import Link from '~/shared/components/Link/Link';
import { useLogoImage } from '~/shared/components/Logo/hooks/useLogoImage';
import { Logo } from '~/shared/components/Logo/Logo';
import { RawHtml } from '~/shared/components/RawHtml/RawHtml';
import { Text } from '~/shared/components/Text/Text';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { useMarket } from '~/shared/utils/market/hooks/useMarket';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { FooterNavigationContainer } from './components/FooterNavigationContainer/FooterNavigationContainer';
import { FooterSubnavigation } from './components/FooterSubnavigation/FooterSubnavigation';
import { SocialMediaIcons } from './components/SocialMediaIcons/SocialMediaIcons';
import { StyledFooter, StyledFooterBCorp, StyledFooterBCorpLink, StyledFooterBCorpLogo, StyledFooterHeader, StyledFooterInner, StyledFooterLeft, StyledFooterLogo, StyledSocialIcons } from './styled';

export const N60Footer = () => {
	const {
		data: { footer, market, vertical: { brandNo } = {} },
	} = useFrame();
	const { culture } = useMarket();
	const { translate } = useTranslation();

	const logo = useLogoImage({
		color: 'light',
		brandNo: brandNo,
		global: true,
	});

	if (!footer) {
		return null;
	}

	const { leftHeadline, leftText, centerHeadline, centerMenu, rightHeadline, rightMenu, copyright, paymentIcons, socialMediaIcons } = footer;

	return (
		<StyledFooter>
			<StyledFooterHeader>
				<StyledFooterLogo
					maxHeightScale={logo.maxHeightScale}
					maxHeightScaleDesktop={logo.maxHeightScaleDesktop}
				>
					<Logo image={logo.image} />
				</StyledFooterLogo>
				<StyledFooterBCorp>
					<StyledFooterBCorpLink
						href="https://www.rosendahl.com/en/intl/rosendahldesigngroup/responsibility/b-corp"
						isExternal
					>
						{translate('b-corp.label')}
					</StyledFooterBCorpLink>
					<StyledFooterBCorpLogo>
						<Link
							href="https://certifikat.emaerket.dk/rosendahl.com"
							isExternal
						>
							<Image
								src={`/assets/logos-external/e-label.png`}
								width={0}
								height={0}
								style={{ height: '95px', width: 'auto' }}
								alt="ee-trustmark"
							/>
						</Link>
						<Link
							href="https://www.rosendahl.com/en/intl/rosendahldesigngroup/responsibility/b-corp"
							isExternal
						>
							<Image
								src={`/assets/logos-external/b-corp-logo_${culture === 'de' ? culture : 'en'}.png`}
								width={0}
								height={0}
								style={{ height: '95px', width: 'auto' }}
								alt="RDG B-Corp logo"
							/>
						</Link>
					</StyledFooterBCorpLogo>
				</StyledFooterBCorp>
			</StyledFooterHeader>
			<StyledFooterInner>
				<StyledFooterLeft>
					{leftHeadline ? (
						<Text
							as="h4"
							variant="bodySmBold"
							marginBottom="2"
						>
							{leftHeadline}
						</Text>
					) : null}

					{leftText?.html ? <RawHtml html={leftText?.html} /> : null}
					{socialMediaIcons?.length ? (
						<StyledSocialIcons>
							<SocialMediaIcons icons={socialMediaIcons} />
						</StyledSocialIcons>
					) : null}
				</StyledFooterLeft>
				<FooterNavigationContainer
					title={centerHeadline}
					navigationItems={centerMenu}
					enableMultiColumns={true}
				></FooterNavigationContainer>

				<FooterNavigationContainer
					hasBorder={true}
					title={rightHeadline}
					navigationItems={rightMenu}
				></FooterNavigationContainer>
			</StyledFooterInner>

			<FooterSubnavigation
				copyright={copyright}
				paymentIcons={paymentIcons}
				marketName={market?.name}
			></FooterSubnavigation>
		</StyledFooter>
	);
};
