import styled from '@emotion/styled';
import { Text } from '~/shared/components/Text/Text';
import { breakpoints } from '~/theme';

export const imageSizes = '96px';

export const StyledProductListItem = styled.div(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: `${theme.space[1]} ${theme.space[3]}`,
	borderBottom: `1px solid ${theme.colors.border}`,
	gap: '20px',

	'> :first-child': {
		flex: '0 0 50px',
	},
	'> :nth-child(2)': {
		flex: '1',
		display: 'flex',
		flexDirection: 'column',
	},
	'> :last-child': {
		flex: '0 auto',
	},

	[breakpoints.md]: {
		'> :first-child': {
			flex: '0 0 80px',
		},
	},
}));

export const StyledText = styled(Text)(({ theme }) => ({
	color: theme.colors.grey60,
}));

export const StyledPriceWrapper = styled.div(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: theme.space[1],
	[breakpoints.md]: {
		flexDirection: 'row',
	},
}));
