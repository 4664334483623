import { Close } from '@radix-ui/react-dialog';
import { useDebouncedEffect } from '@react-hookz/web';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useKeyPressEvent, useUpdateEffect } from 'react-use';
import { useSearchTracking } from '~/features/tracking/hooks/useSearchTracking';
import { SearchResultCategory } from '~/lib/data-contract';
import Link from '~/shared/components/Link/Link';
import { useFrame } from '~/shared/utils/frame/hooks/useFrame';
import { buildURL } from '~/shared/utils/request/utils/buildURL';
import { useRouteTransition } from '~/shared/utils/route-transition/hooks/useRouteTransition';
import { useTranslation } from '~/shared/utils/translation/hooks/useTranslation';
import { useSearchResults } from '../../hooks/useSearchResults';
import { useSearchState } from '../../hooks/useSearchState';
import { getSearchResultLinks } from '../../utils/getSearchResultLinks';
import { SearchInputField } from '../SearchInputField/SearchInputField';
import { CloseButton } from './CloseButton';
import { QuickLinks } from './QuickLinks';
import { SearchProductsArea } from './SearchProductsArea';
import { StyledCloseArea, StyledDialogContainer, StyledDialogContent, StyledDialogPane, StyledList, StyledListLabel, StyledSearchFirstPagesArea, StyledSearchInputArea } from './styled';
import useVisualViewport from '~/shared/hooks/useVisualViewport';
import { SearchSuggestionsResults } from '../SuggestedCategories/SuggestedCategories';
import { getSuggestedCategories } from '../../utils/getSuggestedCategories';

const CloseOnEsc = () => {
	// Made into a component to avoid adding unnecessary listener when the dialog is closed
	const { closeDialog } = useSearchState();
	useKeyPressEvent('Escape', () => {
		closeDialog();
	});
	return null;
};

type SearchForm = {
	searchQuery: string;
};

type SearchDialogContentProps = {
	onCloseClick?: () => void;
};

export const SearchDialogContent = ({ onCloseClick }: SearchDialogContentProps) => {
	const { data: frame } = useFrame();
	const router = useRouter();
	const viewportHeight = useVisualViewport();
	const trackSearchQuery = useSearchTracking('search instant');
	const { translate } = useTranslation();
	const { loading: transitionLoading } = useRouteTransition();
	const { closeDialog, isDialogOpen } = useSearchState();
	const { register, handleSubmit, watch, setValue } = useForm<SearchForm>({
		defaultValues: { searchQuery: '' },
	});
	const [searchTrackingTimeout, setSearchTrackingTimeout] = useState<NodeJS.Timeout | null>(null);
	const [searchedValue, setSearchedValue] = useState('');
	const searchResults = useSearchResults({
		query: searchedValue,
		pageSize: 7,
		minQueryLength: 3,
		onSuccess: (data) => {
			if (searchTrackingTimeout) {
				clearTimeout(searchTrackingTimeout);
			}
			if (data.categories[0].category === SearchResultCategory.product) {
				setSearchTrackingTimeout(
					setTimeout(() => {
						trackSearchQuery(searchedValue, data.categories[0].total);
					}, 3000),
				);
			}
		},
	});

	const suggestedCategoriesResults = useSearchResults({
		query: searchedValue,
		minQueryLength: 3,
		pageSize: 16,
		category: SearchResultCategory.categoryPage,
	});

	const { firstPages, products, totalResults } = useMemo(() => getSearchResultLinks(searchResults.data), [searchResults.data]);
	const suggestedCategories = useMemo(() => getSuggestedCategories(suggestedCategoriesResults.data), [suggestedCategoriesResults.data]);
	const formQueryValue = watch('searchQuery');

	useEffect(() => {
		if (!isDialogOpen && formQueryValue) {
			setValue('searchQuery', '');
		}
	}, [isDialogOpen]);

	useUpdateEffect(() => {
		if (!transitionLoading) {
			closeDialog();
		}
	}, [transitionLoading]);

	useDebouncedEffect(
		() => {
			const cleanedStr = formQueryValue.replace(/\s+/g, ' ').trim();
			setSearchedValue(cleanedStr);
		},
		[formQueryValue],
		300,
	);

	const submitSearch: SubmitHandler<SearchForm> = ({ searchQuery }) => {
		closeDialog();
		if (!searchQuery || !frame.search?.searchResultsLink) {
			return;
		}
		router.push(buildURL(frame.search.searchResultsLink.url, { query: searchQuery }));
	};

	const onCloseSearchDialog = () => {
		onCloseClick && onCloseClick();

		if (!searchedValue || !frame.search?.searchResultsLink) {
			return;
		}

		setSearchedValue('');
	};

	return (
		<StyledDialogContent>
			<Close asChild>
				<StyledCloseArea
					onClick={onCloseSearchDialog}
					aria-hidden
				></StyledCloseArea>
			</Close>
			<StyledDialogContainer>
				<StyledSearchInputArea>
					<form onSubmit={handleSubmit(submitSearch)}>
						<SearchInputField
							{...register('searchQuery')}
							defaultValue={searchedValue}
							onSubmit={handleSubmit(submitSearch)}
						/>
						<CloseButton
							onCloseClick={onCloseSearchDialog}
							label={translate('search.close-label')}
						/>
					</form>
				</StyledSearchInputArea>
				<StyledDialogPane viewportHeight={viewportHeight}>
					<CloseOnEsc />
					<SearchProductsArea
						products={products}
						totalResults={totalResults}
						onSubmit={handleSubmit(submitSearch)}
					/>
					{firstPages.length ? (
						<StyledSearchFirstPagesArea aria-live="polite">
							<StyledListLabel
								as="h3"
								variant="bodySm"
								id="first-pages"
							>
								{translate('search.quick-links-title')}
							</StyledListLabel>
							<StyledList aria-labelledby="first-pages">
								{firstPages.map((link) => (
									<li key={`first-page-${link.id}`}>
										<Link
											title={link.title}
											href={link.url}
										>
											{link.text}
										</Link>
									</li>
								))}
							</StyledList>
						</StyledSearchFirstPagesArea>
					) : suggestedCategories.length ? (
						<SearchSuggestionsResults
							searchWords={searchedValue?.split(' ')}
							results={suggestedCategories}
						/>
					) : (
						<QuickLinks />
					)}
				</StyledDialogPane>
			</StyledDialogContainer>
		</StyledDialogContent>
	);
};
